import React from "react";
import "./index.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import HomeBanner from "./components/HomeBanner";
import ProjectCard from './components/ProjectCard'
import SkillCard from './components/SkillCard'
import ContactForm from './components/ContactForm'
import AnimatedCursor from "react-animated-cursor"

function App() {
  return (
    <>
    <AnimatedCursor
      color="#fff"
      innerSize={8}
      outerSize={50}
      innerScale={1}
      outerScale={2.2}
      outerAlpha={0}
      outerStyle={{
        background: '#ffffff',
        mixBlendMode: 'exclusion'
      }}
      innerStyle={{
        backgroundColor: '#00BD4C'
      }}
    />
    <div>
      <Navbar />
      <HomeBanner id = "home"/>
      <ProjectCard
        id = "project"
        className = "odd"
        projectTitle="Fur Fury - Play and fun!"
        projectDesc="Online game to play with friends, style guess where I attacked and defense."
        projectLink="https://furfury.io"
        deployedProjectLink="https://furfury.io"
        projectImg={require('./images/furfury.png')}
      />

      <ProjectCard
        projectTitle="DEVLANS"
        projectDesc="Official website of the DEVLANS team."
        projectLink="https://devlans.uz"
        deployedProjectLink="https://devlans.uz"
        projectImg={require('./images/devlans.png')}
      />

      <ProjectCard
        className = "odd"
        projectTitle="ЛЭП"
        projectDesc="Производство металлических опор ЛЭП."
        projectLink="https://lepsite.netlify.app"
        deployedProjectLink="https://lepsite.netlify.app"
        projectImg={require('./images/lepsite.png')}
      />
      <SkillCard id = 'skills' />
      <ContactForm id = 'contact' />
      <Footer />
    </div>
    </>
  );
}

export default App;
