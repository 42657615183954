import React from 'react'
import "./SkillCardStyle.css";
import {DiCss3, DiHtml5, DiReact} from "react-icons/di";
import {SiBootstrap, SiTailwindcss, SiTypescript, SiFirebase, SiChakraui, SiRedux, SiSupabase, SiSass } from "react-icons/si";
import {TbBrandJavascript, TbBrandNextjs} from "react-icons/tb";
import { FiFigma, FiGithub } from 'react-icons/fi';

export default function SkillCard({id}) {
  return (
    <>
        <div className="skill-container" id = {id}>
          <div className="skill-header">My Skills</div>
          <div className="skill-box">
            <div className="skillset">
              <abbr title='C++'><DiHtml5 className='techLogo'/></abbr>
              <abbr title='HTML 5'><DiCss3 className='techLogo'/></abbr>
              <abbr title='JavaScript'><TbBrandJavascript className='techLogo'/></abbr>
              <abbr title='JavaScript'><SiBootstrap className='techLogo'/></abbr>
              <abbr title='JavaScript'><DiReact className='techLogo'/></abbr>
            </div>                     
          </div>
          <div className="skill-box">
            <div className="skillset">
              <abbr title='ReactJS'><SiTailwindcss className='techLogo'/></abbr>
              <abbr title='CSS 3'><TbBrandNextjs className='techLogo'/></abbr>
              <abbr title='Git/Github'><SiTypescript className='techLogo'/></abbr>
              <abbr title='NPM'><SiFirebase className='techLogo'/></abbr>
              <abbr title='Bootstrap'><SiChakraui className='techLogo'/></abbr>
            </div>            
          </div>
          <div className="skill-box">
            <div className="skillset">
              <abbr title='Visual Studio Code'><SiRedux className='techLogo'/></abbr>
              <abbr title='Windows'><SiSupabase className='techLogo'/></abbr>
              <abbr title='Figma'><FiFigma className='techLogo'/></abbr>
              <abbr title='Inkscape'><FiGithub className='techLogo'/></abbr>
              <abbr title='Inkscape'><SiSass className='techLogo'/></abbr>
            </div>            
          </div>
          <p>and more...</p>
        </div>
    </>

  )
}
