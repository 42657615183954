import React, { useState } from 'react';
import './ContactFormStyle.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cartoon from '../images/logo.png';
import axios from 'axios';

export default function ContactForm({ id }) {
  const [name, setName] = useState()
  const [address, setAddress] = useState()
  const [desc, setDesc] = useState()

  const onSend = async () => {
    if(name && address && desc) {
        const TOKEN = '5980270602:AAG-3c8dFWiy6_lWwgSAQSYitJF-3T1nyXc'
        const CHAT_ID = '-1001814016840'
        const URL_API = 'https://api.telegram.org/bot' + TOKEN + '/sendMessage'

        let msg = `<b>---------------------------</b>\n`
        msg += `<b>${name}</b>\n`
        msg += `<b>${address}</b>\n`
        msg += `<b>${desc}</b>\n`
        msg += `<b>---------------------------</b>`
        
        await axios.post(URL_API, {
            chat_id: CHAT_ID,
            parse_mode: 'html',
            text: msg
        }, setName(''), setAddress(""), setDesc("")).then(() => {
            toast.success('Message sent successfully.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        })
    } else {
        toast.error("Fill all inputs.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }
  }

  return (
    <div className='contact-window' id={id}>
      <h1>Let's Get in Touch.</h1>
      <p>Discuss a project or just want to say Hi? My inbox is open for all.</p>
      <div className="contact-container">
        <img src={cartoon} alt='Deepa Jha' className="contact-wrapper-left"></img>        
        <div className="contact-wrapper-right">
          <input value={name} onChange={e => setName(e.target.value)} id='name' type="text" name="name" placeholder='Full Name'  required/>
          <input value={address} onChange={e => setAddress(e.target.value)} id='email' type="email" name="email" placeholder='Telegram @username' required />
          <textarea value={desc} onChange={e => setDesc(e.target.value)} id='message' name="message" rows='5' column='15' placeholder='Share your thoughts and insights here; your feedback means a lot.' required/>
          <button className='btn' id='submitBtn' onClick={onSend} value="Send" >Send Message</button>
          <ToastContainer />
        </div>
        
      </div>
    </div>
  )
}